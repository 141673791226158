<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="通知负责人"
      @ok="handleSubmit"
    >
      <span>请确认并维护以下信息：</span>
      <a-form
        class="custom-compact-form"
        style="margin-top:30px;"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="提醒内容">
          <a-textarea
            class="custom-word-break-all"
            :rows="3"
            v-decorator="['content', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入提醒内容' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="洽谈员">{{ talkUserName }}</a-form-item>
        <a-form-item label="发送目标">{{ orderUser }}</a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>

import { sendUserNotice } from '@/api/order'

export default {
  name: 'SendUserNotice',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    hallName: {
      type: String,
      required: true
    },
    deathName: {
      type: String,
      required: true
    },
    orderUser: {
      type: String,
      required: true
    },
    orderNo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'send_user_notice' }),
      submitting: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    talkUserName() {
      return this.$store.getters.userEmpName
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          content: `${this.hallName}(逝者姓名：${this.deathName})，订单编号：${this.orderNo}，订单已完成洽谈。`
        })
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          sendUserNotice(this.serviceOrderId, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
